// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "./bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: rgb(229, 42, 5);
$secondary: rgb(246, 129, 0);
$success: rgb(40, 167, 69);
$light: rgb(233, 236, 239); // change to #f8f9fa ?

$enable-negative-margins: true;

// 3. Include remainder of required Bootstrap stylesheets
@import "./bootstrap/scss/variables";
// @import "./bootstrap/scss/mixins";
// @import "./bootstrap/scss/root";

$primary-light: tint-color($primary, 90%);
$secondary-light: tint-color($secondary, 90%);
$success-light: tint-color($success, 90%);
$danger-light: tint-color($danger, 90%);
$light-light: tint-color($light, 75%);

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

// $font-sizes: (
//   1: $h1-font-size,
//   2: $h2-font-size,
//   3: $h3-font-size,
//   4: $h4-font-size,
//   5: $h5-font-size,
//   6: $h6-font-size
// );

$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;
$accordion-button-focus-box-shadow: none;
$focus-box-shadow: none;
$input-btn-focus-color-opacity: 0;

// Additional theme colors
$custom-colors: (
  "midgray": $gray-400,
  "gray": $gray-600,
);
$colors: map-merge($colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $custom-colors);
$utilities-text-colors: map-merge($utilities-text-colors, $custom-colors);
$utilities-bg-colors: map-merge($utilities-bg-colors, $custom-colors);

// Additional sizes
$custom-spacers: (
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
);
$spacers: map-merge($spacers, $custom-spacers);

@import "./bootstrap/scss/mixins";
@import "./bootstrap/scss/root";

// Include utilities
@import "./bootstrap/scss/utilities";

// Additional width sizes
$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (10: 10%,
          20: 20%,
          30: 30%,
          40: 40%,
          60: 60%,
          70: 70%,
          80: 80%,
          90: 90%),
        ),
      ),
    ),
  )
);

// Responsive height/width classes
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in map-get(map-get($utilities, "width"), "values") {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

// Include optional components

@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/type";
@import "./bootstrap/scss/images";
@import "./bootstrap/scss/containers";
@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/tables";
@import "./bootstrap/scss/forms";
@import "./bootstrap/scss/buttons";
@import "./bootstrap/scss/transitions";
@import "./bootstrap/scss/dropdown";
@import "./bootstrap/scss/button-group";
@import "./bootstrap/scss/nav";
@import "./bootstrap/scss/navbar";
@import "./bootstrap/scss/card";
@import "./bootstrap/scss/accordion";
@import "./bootstrap/scss/breadcrumb";
@import "./bootstrap/scss/pagination";
@import "./bootstrap/scss/badge";
@import "./bootstrap/scss/alert";
@import "./bootstrap/scss/progress";
@import "./bootstrap/scss/list-group";
@import "./bootstrap/scss/close";
@import "./bootstrap/scss/toasts";
@import "./bootstrap/scss/modal";
@import "./bootstrap/scss/tooltip";
@import "./bootstrap/scss/popover";
@import "./bootstrap/scss/carousel";
@import "./bootstrap/scss/spinners";
@import "./bootstrap/scss/offcanvas";
@import "./bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "./bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

.fontBody {
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Lato", sans-serif; */
  font-family: "Roboto", sans-serif;
  /* font-family: "Quicksand", sans-serif; */
  /* font-family: "Questrial", sans-serif; */
  font-weight: 300;
}

.fontHeaders {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

body {
  @extend .fontBody;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .fontHeaders;
}

// Light bg colors
.bg-primary-light {
  background: $primary-light;
}

.bg-secondary-light {
  background: $secondary-light;
}

.bg-light-light {
  background: $light-light;
}

.bg-success-light {
  background: $success-light;
}

.bg-danger-light {
  background: $danger-light;
}

// Buttons - success
.btn-success {
  color: #fff;
  background-color: rgb(40, 167, 69);
  border-color: #28a745;
}

.btn-success:hover, .btn-success:active, .btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.z-9 {
  z-index: 9;
}

.z-99 {
  z-index: 99;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

// .bgHover-[color] classes
@each $color, $value in $theme-colors {
  .bgHover-#{$color}:hover {
    background-color: $value !important;
  }
}

// .textHover-[color] classes
@each $color, $value in $theme-colors {
  .textHover-#{$color}:hover {
    color: $value !important;
  }
}

// Transition
@for $i from 1 through 10 {
  .trans-#{$i} {
    transition: all #{$i}#{"00"}ms;
  }
}

// Position
.posAbs {
  position: absolute;
}

.posRelative {
  position: relative;
}

.posFixed {
  position: fixed;
}

.posSticky {
  position: sticky;
}

.posTop {
  top: 0;
}

.posBottom {
  bottom: 0;
}

.posLeft {
  left: 0;
}

.posRight {
  right: 0;
}

.cursorPointer {
  cursor: pointer;
}

/* Font sizes */

.fontSize0 {
  font-size: 0;
}

.fontSize07 {
  font-size: 0.7rem;
}

.fontSize08 {
  font-size: 0.8rem;
}

.fontSize09 {
  font-size: 0.9rem;
}

.fontSize11 {
  font-size: 1.1rem;
}

.fontSize12 {
  font-size: 1.2rem;
}

.fontSize15 {
  font-size: 1.5rem;
}

.fontSize20 {
  font-size: 2rem;
}

.fontSize25 {
  font-size: 2.5rem;
}

.overflowHidden {
  overflow: hidden;
}

// Text styles
.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.text-super,
.text-sub {
  font-size: 0.83em;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

// Satonda colors
// .btn-satonda {
//   @extend .btn;
//   border-radius: 1000px;
//   padding: 0.6rem 2.5rem;
//   color: $primary;
//   // border: double 3px white;
//   background: linear-gradient(135deg, #f68100 0%, #e52a05 75%);
//   background-origin: border-box;
//   box-shadow: 0px 1000px 0px #fff inset, 0px 3px 7px 0px rgba(0, 0, 0, 0);
//   transition: all 0.3s ease-in-out;
// }

// .btn-satonda:hover {
//   box-shadow: none;
//   // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
//   border-color: rgba(0, 0, 0, 0);
//   color: white;
// }

// .text-satonda {
//   // font-stretch: ultra-condensed;
//   box-decoration-break: clone;
//   display: inline;
//   background: linear-gradient(225deg, #f68100 0%, #e52a05 75%);
//   background: -webkit-gradient(225deg, #f68100 0%, #e52a05 75%);
//   background-clip: border-box;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

// .bg-satonda {
//   background: linear-gradient(225deg, #f68100 0%, #e52a05 75%);
//   background: -webkit-gradient(225deg, #f68100 0%, #e52a05 75%);
// }

.flexSameHeight {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  height: 100%;
}

.toGrow {
  flex: 1 1 auto;
}

.shadow-light {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
}

.shadow-light:hover:not(.noHover) {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
}

.shadow-medium {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.shadow-medium:hover:not(.noHover) {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.shadow-wide {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
}

.shadow-wide:hover:not(.noHover) {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.25);
}

.rounded-4 {
  border-radius: 0.625rem;
}

.rounded-5 {
  border-radius: 1rem;
}

// ====================
// == Main SB layout ==
// ====================
.editPane {  
  box-shadow: -5px 1px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -5px 1px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -5px 1px 10px 0px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

.editResult {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}

.editResult.desktop {
  width: 100%;
  max-width: 100%;
}

.editResult.tablet {
  width: 100%;
  max-width: 768px;
}

.editResult.mobile {
  width: 100%;
  max-width: 350px;
}

#iframe {
  width: 100%;
  height: 100%;
  border: none;
  /* scrollbar-width: thin; */
}

// EditPane accordion
#accordion-EditPane .accordion-button {
  font-size: 1.2rem;
}

.editPane-accordion:hover {
  color: $accordion-button-active-color;
  background-color: $accordion-button-active-bg;
}

// AddNewComponent/EditComponent accordion
#accordion-AddNewComponent .accordion-button, #accordion-EditComponent .accordion-button {
  font-size: 1rem;
}

.addNewComponent-accordion:hover, .addNewComponent-accordion:not(.collapsed),.editComponent-accordion:hover, .editComponent-accordion:not(.collapsed) {
  color: $secondary;
  background-color: $secondary-light;
}

// EditPane templates/gallery buttons
.editPane-btn {
  color: map-get($map: $colors, $key: "gray");
  background-color: #fff;  
}

.editPane-btn:hover {
  color: $primary;
  background-color: $primary-light;
}

// Template preview
.templatePreview {
  max-width: 100%;
  max-height: 280px;
  width: auto;
  height: auto;
}

// Drag & drop hover effects
.dragdrop-hover {
  background-color: #a8a8a8 !important;
  transition: all 300ms;
}

.dragdrop-hover {
  margin-bottom: 16px !important;
}

// Modal menu items
.modalMenuItem {
  padding: 0.25rem 1rem;
  margin: 0;
  cursor: pointer;
  transition: all 300ms;
  border-bottom: 1px solid $gray-300;
}

.modalMenuItem.active, .modalMenuItem:hover {
  color: $primary;
  background-color: $light;
}

// ==================
// == Color picker ==
// ==================

.cpSlider {
  appearance: none;
  -webkit-appearance: none;
  width: 360px;
  height: 30px;
  outline: none;
}

.cpHue {
  background: linear-gradient(to right, #f00 0%, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, #f00 100%);
  background: -webkit-linear-gradient(to right, #f00 0%, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, #f00 100%);
  background: -moz-linear-gradient(to right, #f00 0%, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, #f00 100%);
}

.cpSat {
  background: linear-gradient(to right, hsl(0, 0%, 50%) 0%, hsl(0, 100%, 50%) 100%);
  background: -webkit-linear-gradient(to right, hsl(0, 0%, 50%) 0%, hsl(0, 100%, 50%) 100%);
  background: -moz-linear-gradient(to right, hsl(0, 0%, 50%) 0%, hsl(0, 100%, 50%) 100%);
}

.cpLum {
  background: linear-gradient(to right, hsl(0, 50%, 0%) 0%, hsl(0, 50%, 50%) 50%, hsl(0, 50%, 100%) 100%);
  background: -webkit-linear-gradient(to right, hsl(0, 50%, 0%) 0%, hsl(0, 50%, 50%) 50%, hsl(0, 50%, 100%) 100%);
  background: -moz-linear-gradient(to right, hsl(0, 50%, 0%) 0%, hsl(0, 50%, 50%) 50%, hsl(0, 50%, 100%) 100%);
}

// .cpAlpha {
//   background: url("localhost:3000/assets/img/transparant.png");
//   background-size: cover;
//   background-color: hsla(0, 100%, 50%, 1);
// }

.cpSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 100%;
  background: white;
  cursor: pointer;
  border: 1px solid #212529;
}

.cpSlider::-moz-range-thumb {
  width: 3px;
  height: 100%;
  background: white;
  cursor: pointer;
  border: 1px solid #212529;
}

.cpPreview {
  height: 75px;
  width: 75px;
  border: 1px solid #212529;
  /* background-size: cover; */
  background-color: hsla(0, 100%, 50%, 1);
}

.cpPreviewTransparant {
  // background: url("localhost:3000/assets/img/transparant-square.png");
  height: 75px;
  width: 75px;
  color: black;
}

.cpPresetColor {
  height: 25px;
  width: 25px;
  border: 1px solid #212529;
}

.cpPresetColor:hover {
  scale: 1.1;
}

.cpNumberInputs {
  font-size: 0.8rem;
  width: 30px;
  height: 18px;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  text-align: center;
  float: right;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

.cpNumberInputsHex {
  font-size: 0.8rem;
  width: 100%;
  height: 18px;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  text-align: center;
  /* float: right; */
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

.cpNumberLabels {
  height: 20px;
  width: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  float: left;
  margin: 0;
}

.cpNumberInputRow {
  width: 64px;
  margin: 5px 2px;
  float: left;
}

#cpContainer {
  max-width: 800px;
}

// ============================
// == Color scheme generator ==
// ============================

.CsGeneratorColorRow {
  display: flex;
  flex-direction: row;
}

.CsGeneratorColor:first-child {
  border-radius: 5px 0 0 5px;
}

.CsGeneratorColor:last-child {
  border-radius: 0 5px 5px 0;
}

.CsGeneratorRuleName {
  width: 150px;
  margin: auto;
}

.CsGeneratorColor {
  height: 60px;
  width: 60px;
  /* background-color: #6f42c1; */
}

.CsGeneratorHslValue {
  font-size: 0.8rem;
  color: #6c757d;
  width: 50px;
  margin: auto;
  text-align: center;
}

.CsGeneratorRuleRow {
  transition: 0.2s;
  border-radius: 5px;
  border: 1px dashed rgba(255, 255, 255, 0);
}

.CsGeneratorRuleRow:hover, .CsGeneratorRuleRow.CsGeneratorSelected {
  border-color: #28a745;
  cursor: pointer;
}

// ===================
// == Font selector ==
// ===================

.overallStyleSwapFonts {
  position: absolute;
  left: calc(100px + 200px + 150px + 16px + 15px);
  top: calc(50% - 11px);
  transform: rotate(90deg);
}

.overallStyleSwapFonts:hover {
  transform: rotate(270deg);
}

.previewFontDiv {
  border: 1px dashed transparent;
}

.previewFontDiv:hover, .previewFontDiv.selectedFont {
  border: 1px dashed $success;
}

.fontSelectionPanePreview span {
  position: relative;
  top: -12px;
  opacity: 0;
}

.fontSelectionPanePreview:hover span {
  opacity: 0.8 !important;
}

.fontSelectionPanePreview:hover span:hover {
  opacity: 1 !important;
}

.fontSelectorCategory {
  background: $gray-600;
  cursor: pointer;  
  transition: all 300ms;
  padding: 0.25rem 1rem;
  margin: 0.25rem;
}

.fontSelectorCategory:hover, .fontSelectorCategory.selected {
  background: $primary;
}

// EditForms
.slider-value {
  display: inline-block;
  position: relative;
  width: 45px;
  color: $white;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  background: $primary;
  padding: 4px 0px;
  margin-left: 8px;
  font-size: 0.8rem;

  &:after {
    position: absolute;
    top: 6px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid $primary;
    border-bottom: 6px solid transparent;
    content: '';
  }
}

// ================
// == TextEditor ==
// ================
#collapse-editComponent-texteditor > .accordion-body {
  background: $light-light;
  padding: 1rem 1.25rem !important;
}

#iframe_textEditor {
  width: 100%;
  height: 450px;
}

// ======================
// == Browse templates ==
// ======================

.card-browse-templates, .card-browse-gallery {
  position: relative;
}

.card-browse-templates .flexSameHeight, .card-browse-gallery .flexSameHeight {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 3px rgba(180, 165, 165, 0.2);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);  
  transition: all 0.3s;
  border: 1px solid rgba(0,0,0,0);
}

.card-browse-templates .flexSameHeight {
  cursor: pointer;
}

.card-browse-templates:hover .flexSameHeight {
  box-shadow: 0px 0px 10px 4px rgba(40, 167, 69, 0.3);
  -webkit-box-shadow: 0px 0px 10px 4px rgba(40, 167, 69, 0.3);
  -moz-box-shadow: 0px 0px 10px 4px rgba(40, 167, 69, 0.3);
}

.card-browse-templates.selected .flexSameHeight {
  border-color: $success;
  box-shadow: 0px 0px 10px 3px rgba(40, 167, 69, 0.2);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(40, 167, 69, 0.2);
  -moz-box-shadow: 0px 0px 10px 3px rgba(40, 167, 69, 0.2);
}

.card-browse-templates h5 {
  color: $primary;
  transition: all 0.3s;
  text-align: center;
  margin: 0.5rem 0 0.5rem 0;
}

.card-browse-templates:hover h5 {
  color: $secondary;  
}

.card-browse-templates p {
  color: $dark;
  margin: 0.5rem 0 0.5rem 0;
  font-size: 0.8rem;
}

.card-browse-selecticon {
  position: absolute;
  font-size: 2rem;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: $success;
  border: 1px solid $success;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
}

.card-browse-templates:hover .card-browse-selecticon, .card-browse-gallery:hover .card-browse-gallery-actions {
  visibility: visible;
  opacity: 1;
}

.card-browse-gallery img {
  width: 100%;
  max-height: 230px;
  height: auto;
  object-fit: cover;
}

.card-browse-gallery-actions {
  position: absolute;  
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 9;
  top: 1.5rem;
  right: 1.75rem;
}

// EditForm: ColLayout
.colLayoutOption {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray-300;
  border-radius: 0.625rem;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0.125rem;
  font-size: 0.8rem;
}

.colLayoutOption.selected, .colLayoutOption:hover {
  border-color: $primary;
  color: $primary;
}

.colLayoutOption > div {  
  padding: 0.125rem;
}

.colLayoutOption > div > div {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $light;
  border-radius: 0.25rem;
}